var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from 'lib/components/Badge';
import { Product } from 'lib/enums';
import { centsToDollarsString } from 'lib/helpers';
import { isIndividualOrder } from 'lib/types/order';
import { selectIsPublisher, selectUser } from 'redux/auth';
import { useAppSelector } from 'redux/hooks';
import AdDetailsCard from 'routes/notice/AdDetailsCard';
import LoadingState from 'components/LoadingState';
import { ColumnService } from 'lib/services/directory';
import useSafeAsyncEffect from 'lib/frontend/hooks/useSafeAsyncEffect';
import { isObituaryModel } from 'lib/model/objects/adModel';
import { FuneralHomeDetails } from './FuneralHomeDetails';
import { PublicationDetails } from './PublicationDetails';
import { DeceasedDetailsCard } from './deceasedDetails';
import { AdDetailHeader } from './AdDetailHeader';
import { useOrderEditableData } from '../place/hooks/useOrderEditableData';
export function ObituaryDetail({ order, orderDetail, newspaperOrders, anonymousUserAuthInfo }) {
    var _a, _b;
    const userSnap = useAppSelector(selectUser);
    const isPublisher = useAppSelector(selectIsPublisher);
    const { value: obituary, isLoading: loadingObituary } = useSafeAsyncEffect({
        fetchData: () => __awaiter(this, void 0, void 0, function* () { return order.getAdByVersion(); }),
        dependencies: [order.id, order.modelData.activeVersion]
    });
    const editData = useOrderEditableData({
        userSnap,
        order,
        newspaperOrders: newspaperOrders.map(no => no.modelData)
    });
    if (loadingObituary || !obituary || !isObituaryModel(obituary)) {
        return _jsx(LoadingState, { context: { service: ColumnService.OBITS } });
    }
    return (_jsxs("div", Object.assign({ className: "grid grid-cols-12 gap-3 pb-6" }, { children: [_jsx("div", Object.assign({ className: "col-span-12 flex justify-between lg:py-4" }, { children: _jsx(AdDetailHeader, { title: obituary.modelData.deceasedName, order: order, orderDetail: orderDetail, editData: editData, anonymousUserAuthInfo: anonymousUserAuthInfo }) })), _jsxs("div", Object.assign({ className: "lg:col-span-8 col-span-12 space-y-3" }, { children: [_jsx(AdDetailsCard, Object.assign({ id: "publication-details", header: {
                            title: (_jsxs(_Fragment, { children: [_jsx("span", Object.assign({ className: "mr-4" }, { children: "Publication Details" })), _jsx(Badge, Object.assign({ status: 'info', size: "md" }, { children: isIndividualOrder(order.modelData)
                                            ? 'Individual'
                                            : 'Funeral Home' }))] }))
                        } }, { children: newspaperOrders.map(newspaperOrder => (_jsx(PublicationDetails, { newspaperOrder: newspaperOrder, ad: obituary, editData: editData === null || editData === void 0 ? void 0 : editData[newspaperOrder.id] }, newspaperOrder.id))) })), isPublisher && (_jsx(FuneralHomeDetails, { obituary: obituary, order: order }))] })), _jsxs("div", Object.assign({ className: "lg:col-span-4 col-span-12 space-y-3" }, { children: [_jsxs(AdDetailsCard, Object.assign({ id: "order-details", header: { title: 'Order Details' } }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500 pb-1" }, { children: "Order no" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.id })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Price" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: ((_a = orderDetail.modelData.pricing) === null || _a === void 0 ? void 0 : _a.totalInCents) !== undefined
                                    ? `$${centsToDollarsString((_b = orderDetail.modelData.pricing) === null || _b === void 0 ? void 0 : _b.totalInCents)}`
                                    : '' }))] })), order.modelData.product === Product.Obituary && (_jsx(DeceasedDetailsCard, { obituary: obituary, newspaperOrders: newspaperOrders })), isIndividualOrder(order.modelData) && isPublisher && (_jsxs(AdDetailsCard, Object.assign({ id: "customer-details", header: { title: 'Customer Details' } }, { children: [_jsx("div", Object.assign({ className: "text-column-gray-500 pb-1" }, { children: "Name" })), _jsxs("div", Object.assign({ className: "text-column-gray-400" }, { children: [order.modelData.firstName, " ", order.modelData.lastName] })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Email" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.modelData.contactEmail })), _jsx("div", Object.assign({ className: "text-column-gray-500 pt-4 pb-1" }, { children: "Phone" })), _jsx("div", Object.assign({ className: "text-column-gray-400" }, { children: order.modelData.phone }))] })))] }))] })));
}
