import { NewspaperOrderStatus } from 'lib/types/newspaperOrder';
import { getPublicationTimestampForElasticQuery } from 'lib/utils/dates';
import { CANCELLED_ORDERS_TAB, COMPLETED_ORDERS_TAB, DRAFT_ORDERS_TAB, IN_PROGRESS_ORDERS_TAB } from '../tabs';
export const SHOW_ALL_OPTION_VALUE = 'show all';
export var VerificationFilterString;
(function (VerificationFilterString) {
    VerificationFilterString["COMPLETE"] = "Complete";
    VerificationFilterString["INCOMPLETE"] = "Incomplete";
})(VerificationFilterString || (VerificationFilterString = {}));
export var CustomerTypeString;
(function (CustomerTypeString) {
    CustomerTypeString["FUNERAL_HOME"] = "Funeral home";
    CustomerTypeString["INDIVIDUAL"] = "Individual";
})(CustomerTypeString || (CustomerTypeString = {}));
export const DEFAULT_ORDER_FILTER = {};
// Determine the newspaperOrder status filter according to the selected table tab
const getNewspaperOrderStatusFilter = (tableTap) => {
    switch (tableTap.id) {
        case DRAFT_ORDERS_TAB.id:
            return [NewspaperOrderStatus.DRAFT];
        case IN_PROGRESS_ORDERS_TAB.id:
            return [
                NewspaperOrderStatus.AWAITING_REVIEW,
                NewspaperOrderStatus.IN_REVIEW,
                NewspaperOrderStatus.CONFIRMED
            ];
        case COMPLETED_ORDERS_TAB.id:
            return [NewspaperOrderStatus.COMPLETE, NewspaperOrderStatus.ARCHIVED];
        case CANCELLED_ORDERS_TAB.id:
            return [NewspaperOrderStatus.CANCELLED];
        default:
            return [];
    }
};
/**
 * Returns the set of Elastic filters to be used based on who is viewing which tab of the ads table.
 *
 * Note that the product (ordertype) filter is excluded here as it is a separate, required param
 * in the call to the the orders/search endpoint.
 */
export const getOrderFilters = ({ product, activeOrganization, user, selectedAdTableTab, tableFilters, isPublisher, showAllOrgsNotices, allowedOrganizations }) => {
    var _a, _b;
    const filters = [
        { ordertype: product },
        {
            newspaperorderstatus: getNewspaperOrderStatusFilter(selectedAdTableTab)
        }
    ];
    let allowedOrgs;
    if (showAllOrgsNotices && allowedOrganizations) {
        allowedOrgs = allowedOrganizations.map(o => o.id);
    }
    else {
        allowedOrgs = activeOrganization ? [activeOrganization.id] : [];
    }
    const hasOrganizations = allowedOrgs.length > 0;
    const isViewingDrafts = selectedAdTableTab.id === DRAFT_ORDERS_TAB.id;
    if (isPublisher) {
        filters.push({ publisherid: allowedOrgs });
    }
    else {
        hasOrganizations
            ? filters.push({ advertiserorgid: allowedOrgs })
            : filters.push({ advertiseruserid: user.id });
    }
    if (tableFilters.status) {
        filters.push({ newspaperorderstatus: [tableFilters.status] });
    }
    if (isViewingDrafts) {
        hasOrganizations
            ? filters.push({ authorizedorgid: allowedOrgs })
            : filters.push({ authorizeduserid: user.id });
    }
    if (tableFilters.filingType) {
        filters.push({ filingtype: [tableFilters.filingType] });
    }
    if (tableFilters.verification) {
        filters.push({
            isverified: [
                Number(tableFilters.verification === VerificationFilterString.COMPLETE)
            ]
        });
    }
    if (tableFilters.customerType) {
        filters.push({
            hasadvertiserorg: [
                Number(tableFilters.customerType === CustomerTypeString.FUNERAL_HOME)
            ]
        });
    }
    if ((_a = tableFilters.publicationDate) === null || _a === void 0 ? void 0 : _a.publicationDateFrom) {
        filters.push({
            lastpublishingdate: {
                from: getPublicationTimestampForElasticQuery({
                    dayString: tableFilters.publicationDate.publicationDateFrom
                })
            }
        });
    }
    if ((_b = tableFilters.publicationDate) === null || _b === void 0 ? void 0 : _b.publicationDateTo) {
        filters.push({
            firstpublishingdate: {
                // We add 1 to the publicationDateTo to make the filter inclusive
                to: getPublicationTimestampForElasticQuery({
                    dayString: tableFilters.publicationDate.publicationDateTo
                }) + 1
            }
        });
    }
    return filters;
};
